.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  display: inline-block;
  background-color: var(--s-btn-bg);
  min-width: 190px;
  font-size: 16px;
  line-height: 1.1875;
  font-family: inherit;
  font-weight: normal;
  font-family: 'Basier Square Semibold', sans-serif;
  cursor: pointer;
  color: var(--s-btn-color);
  padding: 16px 46px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 2px solid var(--s-btn-border);
  transition: all 0.4s;
}
.button:hover,
.button:focus {
  background-color: var(--s-btn-bg-hov);
  color: var(--s-btn-color-hov);
}
@media (max-width: 1023px) {
  .button {
    min-width: 160px;
    padding: 16px 30px;
    font-size: 14px;
    line-height: 1.21428571;
  }
}
.button-arrow {
  display: inline-block;
  font-family: 'QuicheSansMedium', serif;
  font-size: 24px;
  line-height: 1.25;
  hyphens: auto;
  cursor: pointer;
  color: var(--s-textcolor);
  padding: 15px 0 15px 98px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  border: none;
  transition: all 0.4s;
  position: relative;
}
.button-arrow:before {
  content: '';
  position: absolute;
  width: 60px;
  height: 60px;
  left: 17px;
  top: 0;
  background-color: var(--s-themecolor);
  border-radius: 1000px;
  transition: all 0.4s;
}
.button-arrow:after {
  content: '';
  width: 56px;
  height: 28px;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 16px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: var(--s-textcolor);
  transition: all 0.4s;
}
.button-arrow:hover,
.button-arrow:focus {
  transform: translateX(40px);
}
.button-arrow:hover:before,
.button-arrow:focus:before {
  opacity: 0.3;
}
@media (max-width: 1023px) {
  .button-arrow {
    font-size: 19px;
    line-height: 1.26315789;
    padding: 8px 0 8px 64px;
  }
  .button-arrow:before {
    width: 40px;
    height: 40px;
    left: 12px;
  }
  .button-arrow:after {
    width: 38px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 10px;
  }
  .button-arrow:hover,
  .button-arrow:focus {
    transform: translateX(22px);
  }
}
.layout1 .content {
  max-width: 550px;
}
.area--two .unitOne .unit__content {
  min-height: 500px;
}
.area--three .unitOne {
  padding-right: 75px;
}
/*# sourceMappingURL=./screen-medium.css.map */